@import "~@staizen/graphene/dist/graphene/graphene.css";
@import "~flag-icon-css/css/flag-icon.min.css";

/* Usage of env(safe-area-inset-[side]) to ensure that page will always be rendered within the safe area
ref: https://webkit.org/blog/7929/designing-websites-for-iphone-x/ */

#webpack-dev-server-client-overlay {
  display: none !important;
}

:root {
  --safe-area-top: env(safe-area-inset-top);
  --safe-area-right: env(safe-area-inset-right);
  --safe-area-bottom: env(safe-area-inset-bottom);
  --safe-area-left: env(safe-area-inset-left);
}

h1 {
  margin: 0;
}

@media screen and (max-width: 1023px) {
  article {
    margin-top: 66px;
  }
}

@media screen and (max-width: 599px) {
  article {
    margin-top: 58px;
  }
}

body {
  background: var(--stz-color-surface-main);
  margin: 0 !important;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}
.status-bar-filler {
  height: env(safe-area-inset-top);
  width: 100%;
  background-color: var(--stz-color-swatch-header-base);
  position: fixed;
  top: 0;
  z-index: 1999; /* to be behind .stz-dialog-overlay */
}
@media screen and (max-width: 599px) {
  .status-bar-filler {
    z-index: 10001; /* to be in front of the element with the highest z-index which is session timeout dialog */
  }
}

.stz-header {
  top: env(safe-area-inset-top);
}

@media screen and (max-width: 1023px) {
  .stz-header {
    height: 50px;
    width: 50px;
    z-index: 1000;
  }
}

.app-link {
  color: var(--stz-color-functional-link);
  cursor: pointer;
  text-decoration: underline;
}
.app-link:hover {
  color: var(--stz-color-functional-link-hovered);
  text-decoration: none;
}
.app-link:active {
  color: var(--stz-color-functional-link-active);
}
.app-link:focus {
  outline: none;
}


.stz-header-usersection-link { /* Remove once update */
  pointer-events: none;
}

.stz-header-logo {
  height: 60px;
  --stz-header-section-logo-image: url("./assets/logo.svg");
}

.stz-header-footer-feedback { /* Remove once update */
  visibility: hidden;
}

[is-open] .stz-header-overlay {
  top: calc(var(--stz-header-section-toggle-height) + env(safe-area-inset-top));
}

/* TODO - Move back to core */
.stz-header-usersection-username { /* Remove once */
  margin-right: var(--stz-space-l);
}

/* Safely display menu icon and side menu within safe area */
/* Only on breakpoints small and below, where side menu is hidden and needs to be toggled */
@media screen and (max-width: 1023px) {
  .stz-header {
    --stz-header-width: calc(240px + var(--safe-area-left));
    left: var(--safe-area-left);
  }

  .stz-header-menu-container.header-menu-container-toggle-animation {
    padding-left: var(--safe-area-left);
    transform: translateX(calc(-100% - var(--safe-area-left)));
  }

  .stz-header-menu-container.header-menu-container-toggle-animation.stz-header-menu-container--open {
    transform: translateX(calc(0px - var(--safe-area-left)));
  }
}

/* Only applies to screens xs and below */
@media screen and (max-width: 599px) {
  .stz-card-header[id^="coach-mark-"] {
    /* To ensure that coach mark is horizontally centred to text */
    width: fit-content;
    padding-right: var(--stz-space-s);
  }
}

/* To prevent the sides of dialogs from being cut off */
.stz-dialog[is-fullscreen] .stz-dialog-content,
.stz-dialog[fullheight] .stz-dialog-content,
.stz-dialog[no-margins] .stz-dialog-content {
  padding-top: calc(var(--stz-space-m) + var(--safe-area-top)); /* Original padding + safe-area, because top unsafe area is padded with filled colour */
  padding-right: max(var(--stz-space-m), var(--safe-area-right)); /* Taking the larger number between the two because there's no padding on unsafe area */
  padding-left: max(var(--stz-space-m), var(--safe-area-left)); /* Taking the larger number between the two because there's no padding on unsafe area */
  padding-bottom: max(var(--stz-space-m), var(--safe-area-bottom)); /* Taking the larger number between the two because there's no padding on unsafe area */
}

/* Ensure that dialog's close button is always in view */
.stz-dialog[is-fullscreen] .stz-dialog-close-button,
.stz-dialog[fullheight] .stz-dialog-close-button,
.stz-dialog[nomargins] .stz-dialog-close-button {
  margin-top: var(--safe-area-top);
  margin-right: var(--safe-area-right);
}

/* TODO - Move back to core */
.stz-sliding-panel {
  --stz-list-details-content-header-background: var(--stz-color-swatch-header-base); /* cpfe-205, rp-490 */
  z-index: unset; /* fix issue where sliding panel is above header menu when both are activated in mobile */
}

.stz-sliding-panel .stz-sliding-panel-small-screen-header {
  flex-basis: calc(50px + env(safe-area-inset-top));
  padding-top: env(safe-area-inset-top);
}

@media screen and (max-width: 599px) {
  .stz-sliding-panel.stz-sliding-panel--activated {
    z-index: 1002; /* fix issue where sliding panel is above header menu when both are activated in mobile */
  }
}

.stz-sliding-panel-content-header {
  flex: 1 0 auto; /* prevent panel content from squashing header */
}

/* Resets the height for icon in the tabs' context menu to ensure it is vertically centered */
stz-tabs stz-context-menu .stz-button-square > stz-icon > svg {
  height: auto;
}

/* hide the image caption on zoom as title might be incorrect */
.__react_modal_image__caption {
  display: none !important;
}
/* force stz-dashboard to always default to a 2 column or smaller width */
.stz-dashboard .stz-dashboard-box {
  margin: var(--stz-space-s) 0;
}
.stz-dashboard .stz-dashboard-column-content {
  margin-bottom: var(--stz-space-s);;
}
/* Fix - RP-786, RP-768, CPFE-440 - prevent width changes upon scrollbar added/removed
  width changes with a scrollbar can cause widgets to enter an infinite resize loop when the scrollbar's bar height is almost 100% causing a two column layout to become a three column layout
  fix: use viewport width to calculate the column width instead of % as vw takes into account scrollbar width
*/
@media screen and (min-width: 1023px) {
  .stz-dashboard-column {
    width: calc(50vw - 135px); /* padding for menu */
  }
}
/* End Fix */
/*
  Fix - CPFE-359 Announcements Widget - https://github.com/staizen/client-cgs-prosperus/pull/494#issuecomment-929042266
  Issue: On single column mobile layout, A dynamic increase in height of a widget will cause an unexpected increase in height of the column BEFORE the dashboard component can recalculate
  the correct increase in height. This causes the screen in Android Webview viewport (tested on 10, 11) to 'jump up' to the previous widget as the last widget
  in the column will wrap to the next column.

  Workaround: Setting an overflow hidden on single columns will allow for unexpected increases in height.
*/
@media screen and (max-width: 599px) {
  .stz-dashboard .stz-dashboard-box {
    overflow: hidden; /* Alternatively it would be cleaner if we can set the overflow hidden via a isRendering class */
  }
}
/* End Fix */
@media (min-width: 600px) {
  .stz-dashboard .stz-dashboard-box {
    margin: var(--stz-space-s);
  }
  .stz-dashboard .stz-dashboard-column-content {
    margin-bottom: var(--stz-space-m);
  }
  .stz-dashboard-box .stz-dashboard-column:nth-child(2n) {
    --stz-dashboard-column-order: 2;
  }
  .stz-dashboard-box .stz-dashboard-column:nth-child(2n+1) {
      --stz-dashboard-column-order: 1;
  }
  .stz-dashboard-box .stz-dashboard-column {
    --stz-dashboard-column-size: 6;
  }
}
.stz-dashboard .context-menu-item[data-context-menu-item="dashboardWidgetRename"],
.stz-dashboard-customisation .context-menu-item[data-context-menu-item="dashboardCustomiseRename"],
.stz-dashboard-manager .stz-dashboard-manager-context-menu  {
  display: none;
}
.stz-dashboard-manager .stz-dashboard-manager-primary-actions {
  display: block !important;
}

.stz-dashboard-customisation-list-item > .stz-dashboard-customisation-list-item-action {
  height: 32px; /* to allow pointer-events: none on disabled stz-button > button to cover React's onClick listener on stzButton */
}

 /*
  reason: iOS requires transform z on an element to create a stacking context
  source: https://stackoverflow.com/questions/40895387/z-index-not-working-on-safari-fine-on-firefox-and-chrome
*/
.os-scrollbar {
  transform: translate3d(0, 0, 200px);
}


/*
  0.6.0-cgs-sales.13 f21fdb74025f1501485aa1ab841f87f9dbda01a3
  scrollbar container patch - breaks relative positioning required by status-loader
*/
.os-content {
  position: initial;
}

.stz-status-bg {
  fill: var(--stz-color-functional-supporting-background);
}
